import React from 'react';
import '../styles/ValueProposition.css';
import '../styles/GreenMessage.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { registerUrl } from '../utils/misc'


const isBrowser = typeof window !== "undefined"

function GreenMessage() {
  const { t } = useTranslation();
  const [rUrl, setRUrl] = React.useState('https://admin.takeaseat.io/register?solution=digital-menu')

  React.useEffect(() => {
    setRUrl(registerUrl())
  }, [])

  return (
    <div className="page ValueProposition green">
      <div className="subcontainer">
        <div className="half-page half-page-left">
          <div>
            <div className="quote-container">
              <h2 className="title" style={{ color: "white" }}>{t(`title`)}</h2>
              <div className="subtitle" >{t(`green-subtitle`)}</div>
              <div className="second-title" style={{ marginTop: "1.5rem" }}>
                <div>{t(`green-features.0`)}</div>
                <div>{t(`green-features.1`)}</div>
                <div>{t(`green-features.2`)}</div>
              </div>
            </div>
            <div style={{ marginTop: "2rem", display: "flex" }}>
              <div style={{ background: "white", display: "flex", borderRadius: "6px" }} className='tas-button-wrapper'>
                <a href={rUrl} rel="noreferrer" className="tas-button outline"
                  onClick={() => {
                    if (isBrowser) {
                      window.gtag_report_conversion(rUrl + "&tier=standard&source=landing.valueproposition")
                      window.gtag('event', 'click_on_get_started');
                    }
                  }}>
                  <span>{t(`free-sign-up`)}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="half-page half-page-right" style={{ marginTop: "unset", display: "flex" }}>
          <div style={{ width: "100%", justifyContent: "end", alignItems: "center", display: "flex", zIndex: "1", aspectRatio: 1, maxWidth: "20rem", margin: "auto" }}>
            <picture style={{ display: "flex", alignItems: "center" }}>
              <source srcSet={"/static/media/images/tree.webp"} type="image/webp" width="370" height="360" loading="lazy" />
              <source srcSet={"/static/media/images/tree.png"} type="image/png" width="370" height="360" loading="lazy" />
              <img src={"/static/media/images/tree.webp"} alt="A green tree" className="menu-sc" width="370" height="360" loading="lazy" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
}


export default GreenMessage;
